<template>
  <div class="lean-improvement-guides-wrapper py-2" v-if="game">
    <div class="columns is-multiline">
      <div
        class="column has-text-centered"
        v-for="temp in templates.sort((a, b) => {
          return a.template_id.localeCompare(b.template_id);
        })"
        :key="temp.template_id"
      >
        <b-field>
          <b-switch v-model="temp.showGuides"
            ><b>Show Guides for {{ temp.name }}</b></b-switch
          >
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanImprovementGuides",
  props: {
    game: Object
  },
  computed: {
    templates() {
      return Object.values(this.newTemplates);
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newTemplates = JSON.parse(
        JSON.stringify(this.game.params.productsTemplates)
      );
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    }
  },
  watch: {
    newTemplates: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "guides", obj: this.newTemplates });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      newTemplates: {}
    };
  }
};
</script>

<style lang="scss">
.lean-improvement-guides-wrapper {
}
</style>
